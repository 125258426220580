import { defineStore } from "pinia";
/**
 * {
    "user": {
        "profile": {
            "id": 806423,
            "firstName": "Brian",
            "lastName": "Clifton",
            "email": "briansclifton75@gmail.com",
            "dateofBirth": "1975-04-16",
            "company": "",
            "unit": "",
            "address": "6549 hollow",
            "city": "Loveland",
            "state": "Ohio",
            "zip": "45140",
            "phone1": "5132006801",
            "phone2": "5132006801",
            "smsNumber": null,
            "offer": false,
            "payment": false,
            "savedSearch": false,
            "smsNotification": 1,
            "smsVerificationStatus": false,
            "emailNotification": false,
            "emailVerificationStatus": true,
            "customerId": "cus_PmhxDSAtbVa263",
            "isAddedCard": true,
            "bcCustomerId": 0,
            "newBidder": true,
            "marketingOptedIn": true,
            "preferredZipcode": "",
            "favoriteLocations": null
        },
        "dashboardCounts": {
            "receipts": 194,
            "archivedReceipts": 0,
            "notifications": 402,
            "watchlist": 0,
            "bids": 0,
            "invoices": 0,
            "cards": 4,
            "winningWatchList": 0,
            "winningBids": 0,
            "winningBidTotal": 0
        },
        "watchlistItemIds": [],
        "isSubscribed": false
    },
    "expires": "2024-08-06T01:55:05.397Z",
    "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4MDY0MjMiLCJpYXQiOjE3MjAzMTczMDUsImV4cCI6MTcyMDQwMzcwNX0.ymBkzrL3WDAsmxmkIV6UiWBfs9GXXDu5WMuKpJCvj9fYziGN0GeBxML2libZjRDlL2hE6sSgbvYK5GfwD5_-tQ"
}
 */

export interface UserProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateofBirth: string;
  company: string;
  unit: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone1: string;
  phone2: string;
  smsNumber: string | null;
  offer: boolean;
  payment: boolean;
  savedSearch: boolean;
  smsNotification: number;
  smsVerificationStatus: boolean;
  emailNotification: boolean;
  emailVerificationStatus: boolean;
  customerId: string;
  isAddedCard: boolean;
  bcCustomerId: number;
  newBidder: boolean;
  marketingOptedIn: boolean;
  preferredZipcode: string;
  favoriteLocations: any;
}

export interface DashboardCounts {
  receipts: number;
  archivedReceipts: number;
  notifications: number;
  watchlist: number;
  bids: number;
  invoices: number;
  cards: number;
  winningWatchList: number;
  winningBids: number;
  winningBidTotal: number;
}

export interface User {
  profile: UserProfile;
  dashboardCounts: DashboardCounts;
  watchlistItemIds: number[];
  isSubscribed: boolean;
}

export interface UserData {
  user: User;
  expires: string;
  accessToken: string;
}

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isLoggedIn: false,
    username: "",
    userData: null as UserData | null,
  }),
  actions: {
    login(username: string, userData: UserData) {
      this.isLoggedIn = true;
      this.username = username;
      this.userData = userData;
      localStorage.setItem('authUser', JSON.stringify(userData));
    },
    logout() {
      this.isLoggedIn = false;
      this.username = "";
      this.userData = null;
      localStorage.removeItem('authUser');
    },
    checkSession() {
      const storedUser = localStorage.getItem('authUser');
      if (storedUser) {
        const userData = JSON.parse(storedUser) as UserData;
        this.isLoggedIn = true;
        this.username = userData.user.profile.email;
        this.userData = userData;
      }
    },
  },
});
